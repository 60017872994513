import { FC, memo } from 'react'

import { Text, Icon, Button } from '@cash-me/react-components'
import NextImage from "next/image"

import { Breadcrumb } from 'components/breadcrumb'
import { Grid, Col } from 'components/grid'
import useWindowSize from 'hooks/useWindowSize'

import { HeroProps } from './hero.model'
import * as S from './styles'

const Hero: FC<HeroProps> = memo(({ onClick }) => {
  const { isMobile } = useWindowSize()
  
  const BreadcrumbLinks = [
    { label: 'Empréstimo para Condomínio' }
  ]

  return (
    <S.Hero>
      <Grid col={{ phone: 1, tablet: 1, desktop: 12 }}>
        <Col col={{ desktop: 11 }} start={{ desktop: 2 }}>
          <S.Content>
            {!isMobile && 
              <S.ImageWrapper>
                <NextImage
                  src={`${process.env.NEXT_PUBLIC_IMAGES_PREFIX}/condo/predio.png?updated=03072022`}
                  alt="Cadeira azul com mesinha e vaso de planta"
                  width="764"
                  height="555"
                  quality="100"
                  priority
                  sizes="100vw"
                  style={{
                    width: "100%",
                    height: "auto"
                  }} />
                <S.LinearGradient />
                <S.LinearGradientVertical />
                <S.Polygons>
                  <S.PinkPolygon type="Triangle" draw="stroke" color="pink" />
                  <S.BluePolygon type="Triangle" draw="fill" color="blue" />
                </S.Polygons>
              </S.ImageWrapper>
            }
            <S.Infos>
              <Breadcrumb items={BreadcrumbLinks} />
              <Text as="h1" size="heading2" weight="medium">
                Empréstimo para {''}
                <br />
                <Text as="span" size="heading2" weight="medium" color="var(--colors-pink-text-default)">
                  condomínio
                </Text>
              </Text>
              <Text color="var(--colors-neutral-text-weak)">
                Invista em melhorias para o seu condomínio e implemente mudanças na vida dos moradores.
              </Text>
              {isMobile && 
                <Button
                  size="lg"
                  onClick={onClick}
                >
                  Simular agora
                </Button>
              }
              <S.LoanInfos>
                <S.LoanInfosItem>
                  <Icon name="graphDown" color="var(--colors-cyan-icon-hover)" />
                  <Text size="body2" color="var(--colors-neutral-text-weak)">
                    Parcelas que cabem no bolso do condomínio.
                  </Text>
                </S.LoanInfosItem>
                <S.LoanInfosItem>
                  <Icon name="calendar" color="var(--colors-cyan-icon-hover)" />
                  <Text size="body2" color="var(--colors-neutral-text-weak)">
                    Carência de até <b>3 meses.</b>
                  </Text>
                </S.LoanInfosItem>
              </S.LoanInfos>
              {!isMobile && 
                <Button size="lg" onClick={onClick} variants="default" iconName="arrowRight">
                  Solicitar Simulação
                </Button>
              }
            </S.Infos>
          </S.Content>
        </Col>
      </Grid>
    </S.Hero>
  );
})

Hero.displayName = 'Hero'

export default Hero
