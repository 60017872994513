import styled, { keyframes } from 'styled-components'

import { theme } from 'styles/theme'

import { ContentStyleProps } from './modal.model'
const { media, radii, colors, space, zIndices } = theme

const fadeIn = keyframes`
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: flex;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const Background = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: ${zIndices.overlay};
  inset: 0;
  background-color: ${colors['neutral-background-contrast-medium']}CC;
  animation: ${fadeIn} 0.4s ease;
`

const slideDown = keyframes`
  0% {
    opacity: 0;
  }
  1% {
    transform: translate(0, -100%);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
`

const sizes = {
  small: `
    max-width: 22rem;
  `,
  medium: `
    max-width: 37.5rem;
  `,
  large: `
    max-width: 46rem;
  `,
  full: `
    max-width: 72rem;
  `
}

export const Content = styled.div<ContentStyleProps>`
  display: block;
  position: relative;
  z-index: ${zIndices.max};
  width: 100%;
  ${p => sizes[p.size]};
  height: auto;
  margin-right: ${space['spacing-sm']};
  margin-left: ${space['spacing-sm']};
  padding: ${space['spacing-lg']};
  border-radius: ${radii['corner-radius-md']};
  background-color: ${colors['neutral-background-default']};
  animation: ${slideDown} 0.6s ease;
`

export const CloseModal = styled.button`
  all: unset;
  position: absolute;
  top: ${space['spacing-lg']};
  right: ${space['spacing-lg']};
  width: ${space['spacing-md']};
  height: ${space['spacing-md']};
  background-color: transparent;
  z-index: ${zIndices[1]};
  cursor: pointer;
`

export const Header = styled.div`
  max-height: 12.5rem;
  max-width: calc(100% - ${space['spacing-lg']});
  margin-bottom: ${space['spacing-lg']};
`

export const InnerContent = styled.div`
  max-height: 70vh;
  overflow: auto;

  @media (${media.desktop}) {
    max-height: 60vh;
  }
`
