import { FC, memo } from "react";

import { Link } from "@cash-me/react-components";

import { BreadcrumbProps } from "./breadcrumb.model";
import * as S from './styles'

export const Breadcrumb:FC<BreadcrumbProps> = memo(({
    items,
    linkColor = 'primary-text-default', 
    textColor = 'neutral-text-weaker'
}) => (
    <S.Wrapper linkColor={linkColor} textColor={textColor}>
        <ol>
            <li>
                <Link href="/">
                    Início
                </Link>
            </li>
            {items.map(item => (
                item.link ?
                <li key={item.label}>
                    <Link href={item.link}>
                        {item.label}
                    </Link>
                </li>
                :
                <li key={item.label}>{item.label}</li>
            ))}
        </ol>
    </S.Wrapper>
))

Breadcrumb.displayName = 'Breadcrumb'