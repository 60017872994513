import { useRef, forwardRef, useImperativeHandle, useCallback, useEffect } from 'react'

import { Loader } from '@cash-me/react-components'
import Script from 'next/script'

import { RDStationFormsWrapper } from 'components/general-styles'
import { Modal, ModalRefsProps } from 'components/modal'

const Form = forwardRef((props, ref) => {
  const modalRef = useRef<ModalRefsProps>(null)
  const formHash = 'UA-115666087-1'
  const formId = 'cashme-condo-hotsite-f80dc8a8a349497537bb'

  const LoadRDStation = useCallback(() => {
    new window['RDStationForms'](formId, formHash).createForm()
  }, [])

  const lockFormOnSubmit = () => {
    const inputText = document.querySelectorAll(`
      #condoForm input[placeholder="Nome *"], 
      #condoForm input[placeholder="Email *"], 
      #condoForm input[placeholder="Cidade *"], 
      #condoForm input[placeholder="Motivo do empréstimo: *"], 
      #condoForm input[placeholder="De quanto precisa? (valor numérico) *"]
    `)
    const inputPhone = document.querySelector('#condoForm input[name="personal_phone"]')
    const submitButton = document.querySelector('#condoForm form button')
    const div = document.querySelector('#test')
    const style = `
      display: flex;
      align-items: center;
      justify-content: center;
      width:100%;
      height:100%;
      background:#fffa;
      position:absolute;
      top:0;
      left:0;
      z-index:101;
      border-radius:1rem;
    `

    submitButton?.addEventListener('click', () => {
      let valid = true
      inputText.forEach((input:Element) => {
        if (input instanceof HTMLInputElement) {
          if (input.value === '') valid = false
        }
      })
      if (inputPhone instanceof HTMLInputElement) {
          if (inputPhone?.value.match(/^\+\d+ $/) !== null) valid = false
      }
      if (valid) div?.setAttribute('style', style)
    })
  }
  
  useImperativeHandle(ref, () => ({
    openModal() {
      modalRef.current?.openModal()
    }
  }))

  useEffect(() => {
    if (window['RDStationForms']) new window['RDStationForms'](formId, formHash).createForm()
  }, [])

  useEffect(() => {
    const timer = setTimeout(lockFormOnSubmit, 9000)
    return () => clearTimeout(timer)
  }, [])

  return (
    <Modal ref={modalRef} title="Contate um consultor">
      <div id="test" style={{ display: 'none' }}>
        <Loader
          id="progress"
          className="added-class"
          color="primary"
          size="md"
          loaderBackground="#fff"
        />
      </div>
      <RDStationFormsWrapper id="condoForm">
        <div id={formId} />

        <Script
          id="RDStation"
          src="https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js"
          strategy="lazyOnload"
          onLoad={LoadRDStation}
        />
      </RDStationFormsWrapper>
    </Modal>
  )
})

Form.displayName = 'Form'

export default Form
