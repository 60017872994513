import { memo, useCallback, useRef } from 'react'

import dynamic from 'next/dynamic'

import { ModalRefsProps } from 'components/modal'
import { CtaBar } from 'modules/cta-bar'
import { Layout } from 'modules/layout'
import { pushDataLayer } from 'utils/push-data-layer'

import { legalText } from './data'
import Form from './form'
import Hero from './hero'

const Benefits = dynamic(() => import('./benefits'))
const Utility = dynamic(() => import('./utility'))
const Renovation = dynamic(() => import('./renovation'))
const Concierge = dynamic(() => import('./concierge'))
const SolarEnergy = dynamic(() => import('./solar-energy'))
const Feedbacks = dynamic(() => import('modules/feedbacks'))
const HowWorks = dynamic(() => import('./how-works'))
const Help = dynamic(() => import('./help'))

const Condo = memo(() => {
  const ref = useRef<ModalRefsProps>(null)

  const handleOpenModal = useCallback((location: string, label: string) => {
    pushDataLayer({
      category: 'Clique',
      action: 'Botões',
      label: `${location} - Empréstimo para Condomínio - ${label}`
    })

    ref?.current?.openModal()
  }, [])

  return (
    <Layout hasCTA footerLegalText={legalText}>
      <Hero onClick={() => handleOpenModal('Banner', 'Solicitar simulação')} />
      <Benefits />
      <Utility />
      <SolarEnergy />
      <Concierge />
      <Renovation />
      <HowWorks onClick={() => handleOpenModal('Como Funciona', 'Solicitar simulação')} />
      <Feedbacks />
      <Help />
      <CtaBar
        title="Realize projetos do seu condomínio"
        buttonLabel="Solicitar simulação"
        onClick={() => handleOpenModal('Flutuante', 'Solicitar simulação')}
      />
      <Form ref={ref} />
    </Layout>
  )
})

Condo.displayName = 'Condo'

export default Condo
