import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { Icon, Text } from '@cash-me/react-components'

import useClickOutside from 'hooks/useClickOutside'
import { useScrollLock } from 'hooks/useScrollLock'

import { ModalProps, ModalRefsProps } from './modal.model'
import * as S from './styles'

export const Modal = forwardRef<ModalRefsProps, ModalProps>(({ size = 'medium', title, children }, ref) => {
  const bgModalRef = useRef<HTMLDivElement>(null)
  const [lockScroll, unlockScroll] = useScrollLock()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useImperativeHandle(ref, () => ({
    openModal() {
      setIsOpen(true)
      lockScroll()
    },

    closeModal() {
      setIsOpen(false)
      unlockScroll()
    }
  }))

  const handleKeyUp = useCallback(
    e => {
      if (e.keyCode === 27) {
        e.preventDefault()
        window.removeEventListener('keyup', handleKeyUp, false)
        unlockScroll()
        setIsOpen(false)
      }
    },
    [unlockScroll]
  )

  const handleClose = useCallback(() => {
    unlockScroll()
    setIsOpen(false)
  }, [unlockScroll])

  useClickOutside(bgModalRef, handleClose)

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp, false)

    return () => {
      window.removeEventListener('keyup', handleKeyUp, false)
    }
  }, [handleKeyUp])

  return (
    <S.Background open={isOpen}>
      <S.Content ref={bgModalRef} size={size}>
        <S.CloseModal onClick={handleClose} aria-label="Close">
          <Icon name="close" color="var(--colors-neutral-icon-default)" />
        </S.CloseModal>

        <S.Header>
          <Text as="h2" size="heading4" weight="medium">
            {title}
          </Text>
        </S.Header>
        <S.InnerContent>{children}</S.InnerContent>
      </S.Content>
    </S.Background>
  )
})

Modal.displayName = 'Modal'
