import styled from 'styled-components'

import { theme } from 'src/styles'

const { space, colors, fontSizes, media } = theme

interface WrapperProps {
  readonly linkColor: string
  readonly textColor: string
}

export const Wrapper = styled.div<WrapperProps>`
  margin-bottom: ${space['spacing-lg']};

  li {
    display: inline;
    color: ${props => colors[props.textColor]};
    font-size: ${fontSizes['font-size-body-2']};
  }

  li:not(:last-child)::after {
    content: '/';
    margin-left: 5px;
    margin-right: 5px;
    color: ${colors['neutral-text-contrast-weak']};
  }

  li a {
    color: ${props => colors[props.linkColor]};
    font-size: ${fontSizes['font-size-body-2']};
  }

  @media (${media.phone}) {
    li,
    li a {
      font-size: ${fontSizes['font-size-caption']};
    }
  }
`
