import styled from 'styled-components'

import { Polygon } from 'components/polygon'
import { theme } from 'styles/theme'

const { colors, media, space, zIndices } = theme

export const Hero = styled.section`
  width: 100%;
  position: relative;
  background-color: ${colors['neutral-background-default']};
  overflow: hidden;

  @media (${media.tablet}) {
    max-height: 35rem;
    padding-bottom: ${space['spacing-xxxl']};
  }
`

export const Content = styled.div`
  @media (${media.tablet}) {
    display: flex;
    flex-direction: row-reverse;
    gap: ${space['spacing-xxl']};
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  width: calc(100% + ${space['spacing-xxl']});
  margin-left: -${space['spacing-md']};
  margin-right: -${space['spacing-md']};

  @media (${media.desktop}) {
    max-height: 35rem;
  }
`

export const LinearGradient = styled.div`
  position: absolute;
  inset: 0 -${space['spacing-md']} -${space['spacing-sm']};
  background: linear-gradient(180deg, rgba(255 255 255 / 0%) 12.64%, #fff 80%);
`

export const LinearGradientVertical = styled.div`
  position: absolute;
  inset: 0 -${space['spacing-md']} -${space['spacing-sm']};
  background: linear-gradient(270deg, rgba(255 255 255 / 0%) 12.64%, #fff 90.25%);

  @media (${media.tablet}) {
    display: none;
  }
`

export const Infos = styled.div`
  position: relative;
  z-index: ${zIndices[1]};
  margin-top: ${space['spacing-lg']};

  h1,
  > p {
    margin-bottom: ${space['spacing-md']};
  }

  .ds-button {
    margin: ${space['spacing-md']} 0;
  }

  @media (${media.tablet}) {
    margin-top: ${space['spacing-xl']};
    margin-bottom: 5.5rem;

    .ds-button {
      width: 19rem;
    }
  }

  @media (${media.desktop}) {
    margin-top: 6.5rem;

    h1 {
      margin-bottom: ${space['spacing-xl']};
    }

    > p {
      margin-bottom: ${space['spacing-lg']};
    }
  }
`

export const LoanInfos = styled.ul`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${space['spacing-md']};
  margin-bottom: ${space['spacing-xl']};

  @media (${media.tablet}) {
    grid-template-columns: 1fr;
    gap: ${space['spacing-sm']};
  }

  @media (${media.desktop}) {
    gap: ${space['spacing-md']};
  }
`

export const LoanInfosItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: ${space['spacing-sm']};

  @media (${media.tablet}) {
    flex-direction: row;
    align-items: center;
  }
`

export const Polygons = styled.div`
  position: absolute;
  z-index: ${zIndices[1]};
  left: 0;
  bottom: ${space['spacing-lg']};

  @media (${media.tablet}) {
    bottom: 0;
  }
`

export const BluePolygon = styled(Polygon)`
  width: 7rem;
  height: 7.9375rem;

  @media (${media.tablet}) {
    width: 16.6875rem;
    height: 18.875rem;
  }
`

export const PinkPolygon = styled(Polygon)`
  position: absolute;
  top: ${space['spacing-xs']};
  left: 3.5625rem;
  width: 5.75rem;
  height: 6.375rem;

  @media (${media.tablet}) {
    top: 1.875rem;
    left: 9.125rem;
    width: 13.375rem;
    height: 15.125rem;
  }
`
