import type { NextPage } from 'next'

import { condo } from 'data/seo'
import Condo from 'views/condo'

const CondoPage: NextPage = () => <Condo />

export default CondoPage

export async function getStaticProps() {
  return {
    props: { SEO: condo }
  }
}
